@import "~react-image-gallery/styles/scss/image-gallery.scss";

/* Force update ant style */
body {
  .ant-input {
    padding: 16px 12px 4px 11px;
    font-size: 15px !important;
  }

  .ant-select .ant-select-selector {

    // padding: 16px 10px 4px 11px;
    .ant-select-selection-item {
      // background-color: var(--bg-secondary);
    }
  }


  .border-none {
    .ant-select-selector {
      border: none !important;
    }
  }


  .ant-select-single .ant-select-selector .ant-select-selection-search {
    top: 16px;
  }

  .ant-btn-default {
    // color: var(--color-primary);
    background-color: transparent;
  }

  .ant-btn-primary {
    background: var(--color-primary);

    &:disabled {
      border-color: #d9d9d9;
      color: var(--font-primary);
      background: var(--color-primary);
      filter: blur(1px);
    }
    &:hover {
      background: #3a2b66 !important;
    }
  }

  .ant-menu-title-content {
    color: var(--font-primary);
  }

  .ant-popover {
    z-index: 99;
    .ant-popover-arrow {
      &::before {
        background: var(--bg-tertiary);
      }
    }

    .ant-popover-title {
      color: var(--font-primary);
    }

    .ant-popover-inner {
      background: var(--bg-tertiary);
      min-width: 200px;

      .ant-popover-inner-content {
        color: var(--font-primary);
      }
    }
  }

  .sidebar-menu {
    // box-shadow: 1px 1px 8px #8568af !important;
  }

  .ant-menu>.ant-menu-item:hover,
  .ant-menu>.ant-menu-submenu:hover,
  .ant-menu>.ant-menu-item-active,
  .ant-menu>.ant-menu-submenu-active,
  .ant-menu>.ant-menu-item-open,
  .ant-menu>.ant-menu-submenu-open,
  .ant-menu>.ant-menu-item-selected,
  .ant-menu>.ant-menu-submenu-selected {
    // color: red;
    border: 2px solid #594a86;
    background-color: #3a2b66;
  }

  .ant-form {
    color: var(--font-primary);
  }

  .project-sel {
    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      background: var(--bg-secondary);
      color: var(--font-primary);
      font-family: var(--primary-font);
      border: 0;
      font-size: 1.25rem;
      line-height: 1.75rem;

      .ant-select-selection-item {
        font-weight: 700;
        background: var(--bg-secondary);
        color: var(--font-primary);
      }
    }

    .ant-select-arrow .anticon {
      color: var(--font-secondary) !important;
    }
  }

  .ant-select-dropdown {
    background: var(--bg-tertiary);
    color: var(--font-primary);

    .ant-select-item.ant-select-item-option {
      color: var(--font-primary);
      background: var(--bg-tertiary);
    }
  }


  .ant-picker-outlined,
  .ant-picker-outlined:focus,
  .ant-picker-outlined:focus-within,
  .ant-picker-outlined:hover {
    background: var(--bg-tertiary);
    border: none;
    // border-style: solid;
    border-color: var(--bg-secondary);
    color: var(--font-primary);

    input {
      font-size: 14px !important;
      line-height: 24px !important;
    }
    input[disabled] {
      color: var(--font-primary) !important;
    }
  }

  :not(.project-sel)> {

    .ant-select-selector,
    .ant-select-selector:focus,
    .ant-select-selector:focus-within,
    .ant-select-selector:hover {
      background: var(--bg-tertiary) !important;
      border: none;
      // border-style: solid;
      border-color: var(--bg-secondary) !important;
      color: var(--font-primary) !important;

      input,
      .ant-select-selection-placeholder {
        font-size: 14px;
      }
    }

  }

  .ant-input-outlined,
  .ant-input-outlined:focus,
  .ant-input-outlined:focus-within,
  .ant-input-outlined:hover,
  .ant-input-outlined.ant-input-status-error:not(.ant-input-disabled),
  .ant-input-outlined.ant-input-status-error:not(.ant-input-disabled):hover,
  .ant-input-outlined.ant-input-status-error:not(.ant-input-disabled):focus-within {
    background: var(--bg-tertiary);
    border: none;
    // border-style: solid;
    border-color: var(--bg-secondary);
    color: var(--font-primary);
  }

  .ant-form-item {
    .ant-form-item-explain-error {
      color: #ff7875;
      text-align: right;
      font-size: 12px;
    }
  }

  .ant-checkbox-wrapper {
    // background: var(--bg-tertiary) !important;
    border: none;
    color: var(--font-primary) !important;

    .ant-checkbox-inner {
      background-color: var(--bg-tertiary);
      border-color: transparent;
    }
  }

  .as-label {
    top: -8px;
    font-size: 12px !important;
    //  background: linear-gradient(to top, #343839 41%, #141718 40%);
    background: var(--bg-primary);
    padding: 0 2px;
    color: var(--font-primary);
    margin-left: -4px;
    border-radius: 4px;
  }

  .anticon {
    color: var(--font-primary);
  }

  .ant-table-wrapper {
    .ant-table-thead tr {
      th {
        background: var(--bg-secondary);
        color: var(--font-primary);
        border: var(--font-secondary);

      &::before {
        background-color: var(--font-secondary) !important;
      }
      }
    }

    .ant-table-placeholder {
        background: var(--bg-secondary) !important;
        color: var(--font-primary) !important;
        &:hover td {
          background: var(--bg-secondary) !important;
        }
        .ant-empty-description {
          color: var(--font-primary) !important;  
        }
    }

  }

  .ant-table-wrapper .ant-table,
  .ant-table-wrapper .ant-table:hover {
    background: var(--bg-secondary);
    color: var(--font-primary);

    td {
      // border: var(--bg-tertiary);
      border-bottom: 1px solid var(--font-secondary);
    }

    .ant-table-row>.ant-table-cell-row-hover,
    .ant-table-row.ant-table-row-selected>.ant-table-cell {
      background: #00000059;
      // background: var(--bg-tertiary);
    }
  }



  .ant-otp {
    display: flex;

    .ant-otp-input {
      padding: 10px !important;
    }
  }

  .ant-breadcrumb li {
    color: var(--font-secondary) !important;
  }

  .ant-breadcrumb a.ant-breadcrumb-link {
    color: var(--font-primary) !important;
  }

  .ant-upload {
    width: 100% !important;
  }

  .ant-input-group-addon {
    border-width: 0 !important;
    
  }

  .info-box {
    background: var(--bg-tertiary);
    width: 330px;
    font-size: 14px;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    padding: 12px;
    text-align: left;
    margin-left: 5px;
    display: flex;
    align-items: center;
    min-height: 100px;
  }

  .info-arrow {
    position: absolute;
    top: 50%;

    &::before {
      position: absolute;
      bottom: 0;
      inset-inline-start: 0;
      width: 16px;
      height: 8px;
      background: var(--antd-arrow-background-color);
      clip-path: polygon(1.6568542494923806px 100%, 50% 1.6568542494923806px, 14.34314575050762px 100%, 1.6568542494923806px 100%);
      clip-path: path('M 0 8 A 4 4 0 0 0 2.82842712474619 6.82842712474619 L 6.585786437626905 3.0710678118654755 A 2 2 0 0 1 9.414213562373096 3.0710678118654755 L 13.17157287525381 6.82842712474619 A 4 4 0 0 0 16 8 Z');
      content: "";
      background: var(--bg-tertiary);
    }

    &::after {
      content: "";
      position: absolute;
      width: 8.970562748477143px;
      height: 8.970562748477143px;
      bottom: 0;
      inset-inline: 0;
      margin: auto;
      border-radius: 0 0 2px 0;
      transform: translateY(50%) rotate(-135deg);
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
      z-index: 0;
      background: transparent;
      background: var(--bg-tertiary);
    }
  }
}


.tiles-masonry {
  columns: 270px;
  column-count: 5;
  column-gap: 15px;
  // columns: 260px;
  // column-gap: 24px;

  .tile-card {
    break-inside: avoid;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
}

.tiles-gallery {
  // columns: 300px;
  // columns: auto;
  column-count: 2;
  column-gap: 0px;

  .tile-gallery-card {
    break-inside: avoid;
    box-sizing: border-box;
    padding: 2px;
    column-fill: balance;
    // margin-bottom: 10px;
  }
}

.image-card-bottom {
  border-radius: 0 0 4px 4px;
  background: #161C24B2;
  backdrop-filter: blur(12px);
  left: 0px;
  bottom: 0px;


  &:hover {
    left: 0px;
    width: 235px;
    border-radius: 6px;
    height: 235px;
  }
}

.status-tab {
  border-radius: 6px;
  padding: 2px 8px;
  // background: var(--bg-tertiary);

  &.in_review {
    color: #FFD234;
    border: 1px solid #FFD234
  }

  &.approved {
    color: #12A58C;
    border: 1px solid #12A58C
  }

  &.rejected {
    color: #cd5c5c;
    border: 1px solid #cd5c5c
  }
  &.redo {
    color: #cd5c5c;
    border: 1px solid #cd5c5c
  }
  &.expired {
    color: #cd5c5c;
    border: 1px solid #cd5c5c
  }
}



#track {
  background: #ffffff40;
}

audio::input[pseudo="-webkit-media-controls-timeline" i]::-internal-media-controls-segmented-track  {
  .-internal-track-segment-background {
    background: #ffffff40;
  }
  .-internal-track-segment-highlight-before {
    background: #ffffff;
  }
  .-internal-track-segment-highlight-after {
    background: #ffffff4f;
  }

}
audio::-webkit-media-controls-enclosure {
  background: transparent;
  border: 1px solid #ffffff6e;
  border-radius: 12px;
}

audio::-webkit-media-controls-panel { 
  padding: 0;
}
audio::-internal-media-controls-overflow-button  { 
  display: none !important;
}
audio::-webkit-media-controls-play-button , audio::-webkit-media-controls-mute-button {
  filter: invert(1);
}
audio {
  padding: 1px 2px;
}

audio::-webkit-media-controls-current-time-display,
audio::-webkit-media-controls-time-remaining-display {
  color: #fff;
  text-shadow: none;
}

.ant-modal-root .ant-modal {
  .ant-modal-content {
      padding: 2rem 4rem;
  }
  .ant-modal-content, .ant-modal-header {
    background-color: var(--bg-primary) !important;
    // background: var(--bg-tertiary);
    color: white !important;
    .ant-modal-close-x {
      color: white !important;
    }
  } 
  .ant-modal-footer {
    .ant-btn.ant-btn-default {
      color: var(--font-primary);
      background-color: transparent;
    }
  
    .ant-btn.ant-btn-primary {
      background: var(--color-primary);
  
      &:hover {
        background: #3a2b66 !important;
      }
    }
  }

  .image-gallery-content:not(.fullscreen) .image-gallery-slide .image-gallery-image {
    max-height: 78vh !important;
  }

  .image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image {
    max-height: 100vh !important;
  }

  .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 60px;
    width: 60px;
  }
}


.ribbon {
  background-color: #1355FF;
  padding: 6px 21px;
  text-align: center;
  position: absolute;
  // top: -16px;
  left: 15px;
  border-radius: 0 0 10px 10px;
  &::before {
    content: "";
    border-right: 15px solid #032F88;
    left: -14px;
    top: -1px;
    position: absolute;
    border-top: 33px solid transparent;
    z-index: -1;
  }
  &::after {
    content: "";
    border-left: 15px solid #032F88;
    right: -14px;
    top: -1px;
    position: absolute;
    border-top: 33px solid transparent;
    z-index: -1;
  }
}

.ant-table-tbody {

  background: var(--bg-primary);
  
}


.ant-pagination .ant-pagination-item-active {
  background-color: var(--bg-secondary);
  border-color: #FFF;
  color: #FFF;
  .ant-pagination-item-link {
    .anticon {

    }
  }
}