html[data-theme="light"] {
    --color-primary: #f4cc70;
    --color-secondary: #6ab187;
    --color-button: #de7a22;
    --color-typography: #20948b;    
    
    --bg-primary: #141718;
    --bg-secondary: #232627;
    --bg-tertiary : #343839;

    --font-primary: #FFFFFF;

    --primary-font: 'Josefin Sans';
    --secondary-font: "Gilroy-Regular", sans-serif;
    --heading-font: "Gilroy-bold", sans-serif;
    --primary-font: "Gilroy-bold", sans-serif;


}


html[data-theme="dark"] {
    --color-primary: #7047EB;
    --color-secondary: #111827;

    --color-button: #7047EB;
    --color-typography: #ff320e;

    --bg-primary: #141718;
    --bg-secondary: #232627;
    --bg-tertiary : #343839;
    --bg-neutral : #ffffff;
    --bg-theme : #7047EB;
    --bg-status : #E7EEFF;

    --bg-active : #12A58C;

    --font-primary: #FFFFFF;
    --font-secondary: #717678;
    --font-tertiary: #D1D5DB;
    --font-card: #DCDCDC;

    --font-review: #1355FF;
    --font-rejected: #F6285F;
    --font-redo: #F6285F;
    --font-approved: #12A58C;

    --primary-font-bkp: 'Josefin Sans';
    --secondary-font: "Gilroy-Regular", sans-serif;
    --heading-font: "Recoleta", sans-serif;
    --primary-font: "Gilroy-bold", sans-serif;

}