

@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer base {
  html {
    margin: 0;
    font-family: "Josefin Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
} */


@font-face {
  font-family: 'Gilroy-Bold';
  src: local('Gilroy'), url("./assets/fonts/gilroy/Gilroy-Bold.ttf") format('opentype');
  /* src: local('Lato'), url(./fonts/Lato-Regular.otf) format('opentype'); */
}

@font-face {
    font-family: 'Gilroy-Regular';
    src: local('Gilroy'), url("./assets/fonts/gilroy/Gilroy-Regular.ttf") format('opentype');
}

@font-face {
    font-family: 'Recoleta';
    src: local('Recoleta'), url("./assets/fonts/recoleta/Recoleta-RegularDEMO.otf") format('opentype');
}

body {
  margin: 0;

  font-family: "'Gilroy-Regular'", sans-serif;
  /* font-family: "Manrope", sans-serif; */
  font-size: 18px;
  font-optical-sizing: auto;
  /* font-weight: <weight>; */
  font-style: normal;


  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-primary);
  color: var(--font-primary);

}

/* code { */
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
/* } */


  /* Style for scrollbar */

    /* width */
    ::-webkit-scrollbar {
      width: 6px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent; 
    }
     
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #9f91ca; 
    /* background: #18fefe61; */
      border-radius: 25px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #7047EB; 
  }