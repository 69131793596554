.float-label {
    position: relative;
  }
  
  .label {
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 11px;
    transition: 0.2s ease all;
  }
  
  .as-placeholder {
    color: gray;
  }
  
  